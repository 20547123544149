import { defineComponent } from "vue";
// @ts-ignore
import { surgaSearchOptions } from "../../ts/search/searchSettingsStateComp";
// @ts-ignore
import { watchlist } from "../../ts/watchlistComp";
// @ts-ignore
//import { assetFileInitiate } from "./../../ts/assetFileHelperComp";
// @ts-ignore
import { listingDetails } from "./../../ts/listingDetailsModuleComp";
// @ts-ignore
import { globalValues } from "@/ts/globalValuesComp";
import { loadingScreen } from "@/ts/loadingScreenComp";

export default defineComponent({
  data() {
    return {
      pageSettings: {} as Element
    };
  },
  async mounted() {
    // retrieve page settings DOM element
    this.pageSettings = document.getElementsByTagName("page-settings")[0];
    globalValues().initiate();
    // initiate core systems.
    surgaSearchOptions().initiate();
    this.initiateListing();
    //assetFileInitiate();
    watchlist().initiate();
    document.addEventListener('readystatechange', (event) => {
      if (document.readyState === "complete") {
        loadingScreen().forceRemoveLoading();
      }
    });
  },
  beforeUnmount() {
    document.removeEventListener('readystatechange', (event) => {
      if (document.readyState === "complete") {
        loadingScreen().forceRemoveLoading();
      }
    });
  },
  // you are setting header position
  methods: {
    initiateListing() {
      const pageType = this.pageSettings.getAttribute("data-page-type");
      if (pageType === "listing") {
        const propertySettings: HTMLElement = document.getElementsByTagName(
          "property-settings"
        )[0] as HTMLElement;
        let propertyIdString = "";
        // retrieve property static id if it exists.
        if (propertySettings !== undefined && propertySettings !== null) {
          propertyIdString = propertySettings.getAttribute("static-id")!;
        }
        listingDetails().initiate(propertyIdString.trim());
      }
    },
    // delay common method for use within the components.
    async delay(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  }
});
