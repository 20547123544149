// Manages background animation
function backgroundGalleryComp() {
  function addGallery(elementRow: HTMLElement, backgroundUrls: string[], delay: number, duration: number) {
    elementRow.style.display = "block";
    let innerHtmlString: string = "";
    if (backgroundUrls.length > 1) {
      innerHtmlString = "<div class='grid-gallery-container'>";
    } else {
      innerHtmlString = "<div class='grid-gallery-container single'>";
    }
    for (let backgroundUrl of backgroundUrls) {
      innerHtmlString += initiateBackground(backgroundUrl);
    }
    innerHtmlString += "</div>";
    elementRow.innerHTML = innerHtmlString;
    const slideElements: HTMLElement[] = elementRow.getElementsByClassName(
      "background-slide"
    ) as unknown as HTMLElement[];
    let galleryElement: HTMLElement = elementRow.getElementsByClassName(
      "grid-gallery-container"
    )[0] as HTMLElement;
    animateSwipeLeft(galleryElement, slideElements, delay, duration);
  }

  async function animateSwipeLeft(
    galleryElement: HTMLElement,
    slideElements: HTMLElement[],
    seconds: number,
    duration: number
  ) {
    let activeSlideNumber: number = 0;
    const totalSlideNumber = slideElements.length;
    slideElements[activeSlideNumber].classList.add("active");
    if (totalSlideNumber > 1) {
      slideElements[getNextSlideNumber(activeSlideNumber, totalSlideNumber)].classList.add("right");
      await delay(seconds * 1000);
      while (true) {
        await swipeLeft(
          galleryElement,
          slideElements,
          activeSlideNumber,
          seconds,
          duration
        );
        activeSlideNumber = getNextSlideNumber(
          activeSlideNumber,
          totalSlideNumber
        );
      }
    }
  }

  async function swipeLeft(
    galleryElement: HTMLElement,
    slideElements: HTMLElement[],
    slideNumber: number,
    seconds: number,
    duration: number
  ) {
    galleryElement.style.transition = "";
    await delay(50);
    galleryElement.classList.remove("animate-left");
    slideElements[slideNumber].classList.add("active");
    slideElements[slideNumber].classList.remove("right");
    slideElements[
      getPreviousSlideNumber(slideNumber, slideElements.length)
    ].classList.remove("active");
    slideElements[getNextSlideNumber(slideNumber, slideElements.length)].classList.add(
      "right"
    );
    await delay(50);
    galleryElement.style.transition = "left " + duration + "s";
    galleryElement.classList.add("animate-left");
    if (seconds === 0) {
      seconds = 1;
    }
    await delay(seconds * 1000 + 100);
  }

  function getNextSlideNumber(
    activeSlideNumber: number,
    totalSlideNumber: number
  ) {
    let nextSlideNumber: number = activeSlideNumber + 1;
    if (nextSlideNumber == totalSlideNumber) {
      nextSlideNumber = 0;
    }
    return nextSlideNumber;
  }

  function getPreviousSlideNumber(
    activeSlideNumber: number,
    totalSlideNumber: number
  ) {
    let previousSlideNumber: number = activeSlideNumber - 1;
    if (previousSlideNumber == -1) {
      previousSlideNumber = totalSlideNumber - 1;
    }
    return previousSlideNumber;
  }

  // delay common method for use within the components.
  async function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function initiateBackground(backgroundUrl: string): string {
    let innerHtmlString = "";
    if (backgroundUrl.endsWith("mp4")) {
      innerHtmlString += initiateBackgroundVideo(backgroundUrl);
    } else {
      innerHtmlString += initiateBackgroundImage(backgroundUrl);
    }
    return innerHtmlString;
  }

  // initiates background image.
  function initiateBackgroundImage(backgroundUrl: string): string {
    return (
      "<div class='background-slide' style='background: url(" +
      backgroundUrl +
      ") center center / cover no-repeat'></div>"
    );
  }

  // initiates background video.
  function initiateBackgroundVideo(backgroundUrl: string): string {
    return (
      "<div class='background-slide'><video autoplay loop muted playsinline><source src='" +
      backgroundUrl +
      "' type='video/mp4'></video></div>"
    );
  }
  return { addGallery };
}
export { backgroundGalleryComp };
