import { globalValues } from "./globalValuesComp";
import {
  getLocaleCurrency,
  getLocaleForLease,
  getPAorPAX,
} from "./localeComp";

let useSuperScript: boolean = null;

function detectBrackets(initialString: string): string {
  const firstIndex: number = initialString.indexOf("{{");
  const lastIndex: number = initialString.indexOf("}}", firstIndex);
  // check if bracket is complete. if not return empty string.
  if (firstIndex === -1 || lastIndex === -1) {
    return "";
  }
  const replacementString = initialString.slice(firstIndex, lastIndex + 2);
  return replacementString;
}

function manageListingTypeString(surgaListingType: string): string {
  let result: string = "";
  let divider = "";
  surgaListingType = surgaListingType.toLowerCase();
  if (surgaListingType === null || surgaListingType === undefined) {
    return "";
  }
  if (surgaListingType.includes("v")) {
    result += "FOR SALE";
    divider = " / ";
  }
  if (surgaListingType.includes("l")) {
    result += divider + getLocaleForLease().toUpperCase();
    divider = " / ";
  }
  if (surgaListingType.includes("i")) {
    result += divider + "INVESTMENT";
    divider = " / ";
  }
  return result;
}

function managePrice(price: number, isLease: boolean = false) {
  if (price === undefined || price === 0) {
    return "";
  } else {
    let unit: string = "";
    if (isLease) {
      unit = " " + getPAorPAX();
    }
    return getLocaleCurrency(price) + unit;
  }
}
function managePriceRange(
  priceMin: number,
  priceMax: number,
  isLease: boolean = false,
  isArea: boolean = false,
  UOM: string = "",
  rentPeriod: string = ""
): string {
  let displayPriceMin = true;
  let displayPriceMax = true;
  if (priceMin === undefined || priceMin === 0) {
    displayPriceMin = false;
  }
  if (priceMax === undefined || priceMax === 0) {
    displayPriceMax = false;
  }
  let unit = "";
  const landUOM = getAreaUOM(UOM).trim();
  if (isLease) {
    if (isArea) {
      unit = " per " + landUOM;
    }
    unit += " " + getTime(rentPeriod, !(unit.length > 0));
  }
  if (displayPriceMin && displayPriceMax) {
    if (priceMin === priceMax) {
      return getLocaleCurrency(priceMax) + unit;
    }
    return (
      getLocaleCurrency(priceMin) +
      " - " +
      getLocaleCurrency(priceMax) +
      unit
    );
  }
  if (displayPriceMax) {
    return getLocaleCurrency(priceMax) + unit;
  }
  if (displayPriceMin) {
    return getLocaleCurrency(priceMin) + unit;
  }
  return "";
}

function manageLandRange(
  landMin: number,
  landMax: number,
  bldUOM: string
): string {
  let displayPriceMin = true;
  let displayPriceMax = true;
  if (landMin === undefined || landMin === 0) {
    displayPriceMin = false;
  }
  if (landMax === undefined || landMax === 0) {
    displayPriceMax = false;
  }
  if (bldUOM.toLowerCase() === "hectares") {
    landMin = Math.round(landMin / 10000);
    landMax = Math.round(landMax / 10000);
  }
  if (displayPriceMin && displayPriceMax) {
    if (landMin === landMax) {
      return landMax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
      landMin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      " - " +
      landMax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  }
  if (displayPriceMax) {
    return landMax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (displayPriceMin) {
    return landMin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "";
}

function getAreaUOM(bldUOM: string): string {
  if (useSuperScript == null)
    useSuperScript = globalValues().getBoolean("useSuperScript");
  if (bldUOM === undefined || bldUOM === null) {
    bldUOM = "";
  }
  bldUOM = bldUOM.trim();
  if (bldUOM === "sqm" || bldUOM === "m2" || bldUOM === "m²") {
    if (useSuperScript) {
      bldUOM = "m²";
    } else {
      bldUOM = " sqm";
    }
  }
  if (bldUOM === "sqft" || bldUOM === "ft²" || bldUOM === "sq.ft.") {
    if (useSuperScript) {
      bldUOM = "ft²";
    } else {
      bldUOM = " sq.ft.";
    }
  }
  return bldUOM;
}

function getTime(time: string, abbreviateAnnum: boolean): string {
  switch (time.toLowerCase()) {
    case "monthly":
    case "m":
      return "per month";
    case "quarterly":
    case "q":
      return "per quarter";
    case "fortnightly":
    case "f":
      return "per fortnight";
    case "weekly":
    case "w":
      return "per week";
    default: {
      if (abbreviateAnnum) {
        return getPAorPAX();
      } else {
        return "per annum";
      }
    }
  }
}
// determines if property is lease property.
function isLeaseProperty(listingType: string): boolean {
  if (listingType === null || listingType === undefined) {
    return false;
  }
  return listingType.includes("L");
}

// determines if property is sale/investment property.
function isSaleProperty(listingType: string): boolean {
  if (listingType === null || listingType === undefined) {
    return false;
  }
  return listingType.includes("V") || listingType.includes("I");
}
export { detectBrackets, manageListingTypeString, managePriceRange, manageLandRange, managePrice, getAreaUOM, getTime, isLeaseProperty, isSaleProperty };
