import { ref } from "vue";

let totalAsyncModules: number = -1;
let currentLoadedAsyncModules: number = 0;
let pageLoaded: boolean = false;
const loadingState = ref(0 as number);
function loadingScreen() {
  function addMax(totalModules: number): void {
    totalAsyncModules = totalModules;
    checkLoadedModules();
  }

  function addLoadedModule(): void {
    currentLoadedAsyncModules++;
    if (pageLoaded) {
      console.log("Async Module count over max limit.");
    }
    checkLoadedModules();
  }
  function forceRemoveLoading(): void {
    if (!pageLoaded) {
      pageLoaded = true;
      console.log("Loading screen forcefully removed.");
      removeLoadingScreen();
    }
  }

  function checkLoadedModules() {
    if (totalAsyncModules == currentLoadedAsyncModules && !pageLoaded) {
      pageLoaded = true;
      removeLoadingScreen();
    }
  }

  function getLoadState() {
    return loadingState;
  }

  async function removeLoadingScreen() {
      const loadElement: HTMLElement = document.getElementsByClassName("loading-overlay")[0] as HTMLElement;
      const bodyElement: HTMLElement = document.getElementsByClassName("body")[0] as HTMLElement;
      await delay(100);
      loadingState.value = 1;
      bodyElement.style.opacity = "1";
      loadElement.style.opacity = "0";
      await delay(200);
      loadElement.style.display = "none";
  }

  async function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  return { addMax, addLoadedModule, forceRemoveLoading, getLoadState }; 
}
export { loadingScreen };
