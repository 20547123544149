//stores data required by search function.
// contains list of all suburbs, streets, postcodes and states mentioned in asssetfile.
export class SearchFieldsModel{
  public suburbs: string[];
  public streets: string[];
  public postcodes: string[];
  public states: string[];
  public siteName: string[];
  public city: string[];
  constructor() {
    this.suburbs = [];
    this.streets = [];
    this.postcodes = [];
    this.states = [];
    this.siteName = [];
    this.city = [];
  }
  public JSONcopy(jsobject: any): void {
    this.suburbs = jsobject.suburbs;
    this.streets = jsobject.streets;
    this.postcodes = jsobject.postcodes;
    this.states = jsobject.states;
    this.siteName = jsobject.siteName;
    this.city = jsobject.city;
  }
}