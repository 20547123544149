import { reactive } from "vue";
import { globalValues } from "./globalValuesComp";
import { AssetFile, PropertyType } from "./models/assetFileModel";
import { searchHelperComp } from "./searchHelperComp";
import { stateStorage } from "./stateStorageComp";

const assetfileMd5Token = "global-asset-file-md5";
const assetfileObjectStore = "global-asset-file-object";
const state = reactive({ assetObject: {} as AssetFile });
let initiated: boolean = false;
async function assetFileInitiate() {
  if (initiated) {
    return;
  } else {
    initiated = true;
  }
  state.assetObject = new AssetFile();
  let url: string = globalValues().get("blobUrl", "");
  if (!url.endsWith("/")) {
    url += "/";
  }
  url = url + "assets/" + (globalValues().get("assetFileName", ""));
  const xmlRequest: XMLHttpRequest = await retrieveXML(url);
  // retrieve md5 string. Used to test if xml file is new.
  let MD5: string;
  try {
    MD5 = xmlRequest.getResponseHeader("content-md5");
  } catch (ex) {
    //catch error incase response header is restricted in CORS.
    MD5 = "";
  }
  let resetAssetFile: boolean = !isCompareMD5(MD5);
  // check if md5 is same, if so, retrieve data from memory.
  if (!resetAssetFile) {
    let assetObject: Object = stateStorage(assetfileObjectStore).get()
      .value as AssetFile;
    state.assetObject.JSONcopy(assetObject);
    // initiate and retrieve saved object from local store.
    searchHelperComp().initiate();
    searchHelperComp().localStore().retrieve();
    // check if search settings have been altered. If search settings is valid exit function, otherwise reset asset file.
    if (
      !await checkSearchSettingsValidity(
        searchHelperComp().recommendations().getState().recommendationData
          .isRelaxed
      )
    ) {
      searchHelperComp().resetRecommended();
      resetAssetFile = true;
    }
  }
  if (resetAssetFile) {
    stateStorage(assetfileMd5Token).set(MD5 as unknown as object);
    const xmlDoc: Document = xmlRequest.response;
    state.assetObject = new AssetFile(xmlDoc as unknown as string);
    stateStorage(assetfileObjectStore).set(state.assetObject);
  }
  // checks if search settings has changed.
  // returns true if still the same.
  async function checkSearchSettingsValidity(
    isRelaxedPrev: boolean
  ): Promise<boolean> {
    const notRelaxedString: string = globalValues().get(
      "notRelaxedSearch"
    );
    let isRelaxed: boolean;
    if (notRelaxedString === "True") {
      isRelaxed = false;
    } else {
      isRelaxed = true;
    }
    return isRelaxed === isRelaxedPrev;
  }
  // compares current md5 string with saved md5 string returns true if matching.
  function isCompareMD5(md5: string): boolean {
    const assetmd5Object: object = stateStorage(assetfileMd5Token).get().value;
    // check if empty
    try {
      if (Object.keys(assetmd5Object).length === 0) {
        return false;
      }
      let assetmd5 = assetmd5Object as unknown as string;
      return assetmd5 === md5;
    } catch (ex) {
      return false;
    }
  }
  function retrieveXML(xmlFile: string): Promise<XMLHttpRequest> {
    return new Promise((resolve, reject) => {
      const xmlhttp = new XMLHttpRequest();
      xmlhttp.open("GET", xmlFile, true);
      if (xmlhttp.overrideMimeType) {
        xmlhttp.overrideMimeType("text/xml");
      }
      xmlhttp.onload = () => {
        return resolve(xmlhttp);
      };
      xmlhttp.onerror = () => {
        return reject(new Error("Asset file not found."));
      };
      xmlhttp.send();
    });
  }
}
function assetFileManager() {
  function get() {
    function assetObjectReactive() {
      return state;
    }

    // retrieves property Type with all dropdown.
    function propertyTypeWithAll(): PropertyType[] {
      const emptyPropertyType: PropertyType = new PropertyType({
        propcode: "",
        _parameter: globalValues().getDefaultIfEmpty("propertyTypeDisplay", "All")
      });
      let propertyList: PropertyType[];
      if (state.assetObject.propertyType !== undefined) {
        propertyList = [...state.assetObject.propertyType];
      } else {
        propertyList = [];
      }
      propertyList.unshift(emptyPropertyType);
      return propertyList;
    }
    return { assetObjectReactive, propertyTypeWithAll };
  }
  return { get };
}
export { assetFileInitiate, assetFileManager };
