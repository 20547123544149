export class SearchFilters {
  public minPrice: number;
  public maxPrice: number;
  public minArea: number;
  public maxArea: number;
  public propertyType: string;
  [key: string]: any;

  constructor() {
    this.minPrice = -1;
    this.maxPrice = -1;
    this.minArea = -1;
    this.maxArea = -1;
    this.propertyType = "";
  }

  public cast(castModel: Record<string, any>) {
    if (castModel["minPrice"]) {
      this.minPrice = castModel["minPrice"];
    }
    if (castModel["maxPrice"]) {
      this.maxPrice = castModel["maxPrice"];
    }
    if (castModel["minArea"]) {
      this.minArea = castModel["minArea"];
    }
    if (castModel["maxArea"]) {
      this.maxArea = castModel["maxArea"];
    }
    if (castModel["propertyType"]) {
      this.propertyType = castModel["propertyType"];
    }
  }

  // check if any parameter is found in URL. returns true if it is and false if it is not.
  public castParameters(castModel: Record<string, any>): boolean {
    let objectKeys: string[] = Object.keys(this);
    let isUseParameters: boolean = false;
    for (var key of objectKeys) {
      if (castModel.hasOwnProperty(key)) {
        isUseParameters = true;
      }
    }
    return isUseParameters;
  }

  // match parameters to defaults to determine if 
  public getParameterChanges(
    result: (Record<string, string | boolean | number> | Array<string>)[]
  ): (Record<string, string | boolean | number> | Array<string>)[] {
    const defaultSF: SearchFilters = new SearchFilters();
    if (this.minPrice !== defaultSF.minPrice) {
      result[0]["minPrice"] = this.minPrice;
    } else {
      (result[1] as Array<string>).push("minPrice");
    }
    if (this.maxPrice !== defaultSF.maxPrice) {
      result[0]["maxPrice"] = this.maxPrice;
    } else {
      (result[1] as Array<string>).push("maxPrice");
    }
    if (this.minArea !== defaultSF.minArea) {
      result[0]["minArea"] = this.minArea;
    } else {
      (result[1] as Array<string>).push("minArea");
    }
    if (this.maxArea !== defaultSF.maxArea) {
      result[0]["maxArea"] = this.maxArea;
    } else {
      (result[1] as Array<string>).push("maxArea");
    }
    if (this.propertyType !== defaultSF.propertyType) {
      result[0]["propertyType"] = this.propertyType;
    } else {
      (result[1] as Array<string>).push("propertyType");
    }
    return result;
  }
}
