import { version } from "@/vue-components/VERSION";
import { computed, ref } from "vue";
import { Store } from "./models/storeModel";
const state = ref({ storage: [] as Store[] });
const softwareVersion = "-" + version().get();

function stateStorage(id: string) {
  // set item by id in storage.
  function set(storeObject: object) {
    // attempt to save to stores.
    let isSaved: boolean = false;
    state.value.storage.forEach((element, index) => {
      if (id === element.id) {
        state.value.storage[index].data = storeObject;
        isSaved = true;
      }
    });
    // if item is new save new object.
    if (!isSaved) {
      state.value.storage.push(new Store(id, storeObject));
    }
    localStorage.setItem(id + softwareVersion, JSON.stringify(storeObject));
  }

  function get() {
    return {
      computed: computed(() => getData()),
      value: getData()
    };
  }
  function exists() {
    return {
      computed: computed(() => Object.keys(getData()).length > 0),
      value: Object.keys(getData()).length > 0
    };
  }
  function remove() {
    let deleteIndex: number = -1;
    state.value.storage.forEach((element, index) => {
      if (id === element.id) {
        deleteIndex = index;
      }
    });
    state.value.storage.splice(deleteIndex, 1);
    localStorage.removeItem(id + softwareVersion);
  }

  // finds item and retrives from store.
  function getData(): object {
    let data = {};
    let found: boolean = false;
    // attempt to find item in reactive object.
    state.value.storage.forEach((element) => {
      if (id === element.id) {
        data = element.data;
        found = true;
      }
    });
    // if not found attempt to retrieve from local storage.
    if (!found && localStorage.getItem(id + softwareVersion)) {
      const dataStore: Store = new Store(
        id,
        JSON.parse(localStorage.getItem(id + softwareVersion))
      );
      state.value.storage.push(dataStore);
      data = dataStore.data;
    } else if (!found) {
      // if item being searched is global-settings-hash, and it is not found, clear all storage
      let storageKey = localStorage.key(0);
      if (!storageKey || !storageKey.endsWith(softwareVersion)) {
        localStorage.clear();
      }
    }
    return data;
  }

  return {
    exists,
    get,
    remove,
    set
  };
}

export { stateStorage };
