function validate() {
  // validates size values given by the user through Umbraco.
  function size(umbracoInput: string = "", defaultValue: string): string {
    // check for default string
    if (!umbracoInput || umbracoInput === "--default") {
      return defaultValue;
    }
    let regexArray = ["^((\\d*)|(\\d*\\.?\\d*))$"];
    let validateReply = validateInputNoLog(
      umbracoInput,
      defaultValue,
      regexArray
    );
    if (validateReply[0] === "success") {
      return umbracoInput + "pt";
    } else if (validateReply[0] === "empty") {
      validateEmptyError("css size", defaultValue);
      return defaultValue;
    } else if (removeAllWhiteSpaceLower(umbracoInput) === "default") {
      return defaultValue;
    }
    regexArray = [
      "^var\\(--.*\\)$",
      "^((\\d*)|(\\d*\\.?\\d*)cm)$",
      "^((\\d*)|(\\d*\\.?\\d*)mm)$",
      "^((\\d*)|(\\d*\\.?\\d*)in)$",
      "^((\\d*)|(\\d*\\.?\\d*)px)$",
      "^((\\d*)|(\\d*\\.?\\d*)pt)$",
      "^((\\d*)|(\\d*\\.?\\d*)pc)$",
      "^((\\d*)|(\\d*\\.?\\d*)em)$",
      "^((\\d*)|(\\d*\\.?\\d*)ex)$",
      "^((\\d*)|(\\d*\\.?\\d*)ch)$",
      "^((\\d*)|(\\d*\\.?\\d*)rem)$",
      "^((\\d*)|(\\d*\\.?\\d*)vw)$",
      "^((\\d*)|(\\d*\\.?\\d*)vh)$",
      "^((\\d*)|(\\d*\\.?\\d*)vmin)$",
      "^((\\d*)|(\\d*\\.?\\d*)vmax)$",
      "^((\\d*)|(\\d*\\.?\\d*)%)$"
    ];
    validateReply = validateInput(
      umbracoInput,
      defaultValue,
      regexArray,
      "css size"
    );
    return validateReply[1];
  }

  // validates font weight values given by the user through Umbraco.
  function fontWeight(umbracoInput: string, defaultValue: string): string {
    if (!umbracoInput || umbracoInput === "--default") {
      return defaultValue;
    }
    const regexArray = [
      "^var\\(--.*\\)$",
      "^([1-9])00$",
      "^normal$",
      "^bold$",
      "^bolder$",
      "^lighter$",
      "^initial$",
      "^inherit$"
    ];
    const validateReply = validateInput(
      umbracoInput,
      defaultValue,
      regexArray,
      "font weight"
    );
    return validateReply[1];
  }

  // validates any item, ensures it is not null then returns object.
  function notNullOrUndefined(umbracoInput: any, defaultValue: any): any {
    if (!umbracoInput || umbracoInput === "--default") {
      return defaultValue;
    }
    return umbracoInput;
  }

  // validates font weight values given by the user through Umbraco.
  function lineHeight(umbracoInput: string, defaultValue: string) {
    if (!umbracoInput || umbracoInput === "--default") {
      return defaultValue;
    }
    const regexArray = ["^[0-9]*(\\.[0-9]+)?$"];
    const validateReply = validateInput(
      umbracoInput,
      defaultValue,
      regexArray,
      "font weight"
    );
    return validateReply[1];
  }

  // validates font Style values given by the user though Umbraco.
  function fontStyle(umbracoInput: string, defaultValue: string): string {
    if (!umbracoInput || umbracoInput === "--default") {
      return defaultValue;
    }
    const regexArray = ["normal", "italic", "oblique"];
    const validateReply = validateInput(
      umbracoInput,
      defaultValue,
      regexArray,
      "font style"
    );
    return validateReply[1];
  }

  // validates font tag values given by the user though Umbraco.
  function fontTag(umbracoInput: string, defaultValue: string): string {
    if (!umbracoInput || umbracoInput === "--default") {
      return defaultValue;
    }
    const regexArray = ["h1", "h2", "h3", "h4", "p", "a"];
    const validateReply = validateInput(
      umbracoInput,
      defaultValue,
      regexArray,
      "font tag"
    );
    return validateReply[1];
  }

  // validates inputs for property text align.
  function textAlign(umbracoInput: string, defaultValue: string): string {
    if (!umbracoInput || umbracoInput === "--default") {
      return defaultValue;
    }
    const regexArray = ["center", "left", "right", "justify"];
    const validateReply = validateInput(
      umbracoInput,
      defaultValue,
      regexArray,
      "text-align value"
    );
    return validateReply[1];
  }

  // validates image inputs.
  function imageUrl(umbracoInput: string, defaultValue: string): string {
    if (defaultValue == null) {
      defaultValue = "/Scripts/src/assets/ImageNotFound.png";
    }
    if (umbracoInput == null || umbracoInput === "--default") {
      return defaultValue;
    }
    const regexArray = ["^.{1,}$"];
    const validateReply = validateInput(
      umbracoInput,
      defaultValue,
      regexArray,
      "image"
    );
    return validateReply[1];
  }

  // validates string inputs.
  function string(umbracoInput: string, defaultValue: string): string {
    if (!umbracoInput || umbracoInput === "--default") {
      return defaultValue;
    }
    if (defaultValue == null) {
      defaultValue = "";
    }
    const regexArray = ["^.{1,}$"];
    const validateReply = validateInput(
      umbracoInput,
      defaultValue,
      regexArray,
      "text string"
    );
    if (validateReply[0] === "success") {
      return umbracoInput;
    } else {
      return defaultValue;
    }
  }

  // validates number inputs.
  function number(umbracoInput: any, defaultValue: number): number {
    if (!umbracoInput || umbracoInput === "--default") {
      return defaultValue;
    }
    if (!defaultValue) {
      defaultValue = 0;
    }
    const regexArray = ["^-?[0-9]*(\\.[0-9]+)?$"];
    try {
      const validateReply = validateInput(
        umbracoInput,
        defaultValue,
        regexArray,
        "number"
      );
      return Number(validateReply[1]);
    } catch (ex) {
      return defaultValue;
    }
  }

  // validates number inputs allows negative numbers.
  function numberAllowNegative(
    umbracoInput: any,
    defaultValue: number
  ): number {
    if (!umbracoInput || umbracoInput === "--default") {
      return defaultValue;
    }
    if (!defaultValue) {
      defaultValue = 0;
    }
    const regexArray = ["^-?[0-9]*(\\.[0-9]+)?$"];
    try {
      const validateReply = validateInput(
        umbracoInput,
        defaultValue.toString(),
        regexArray,
        "number"
      );
      return Number(validateReply[1]);
    } catch (ex) {
      return defaultValue;
    }
  }

  // validates colour inputs.
  function colour(umbracoInput: string, defaultValue: string): string {
    if (!umbracoInput || umbracoInput === "--default") {
      return defaultValue;
    }
    const regexArray = [
      "^var\\(--.*\\)$",
      "^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$",
      "^rgb\\(.*\\)$",
      "^rgba\\(.*\\)$",
      "^hsla\\(.*\\)$"
    ];
    const validateReply = validateInput(
      removeAllWhiteSpace(umbracoInput),
      defaultValue,
      regexArray,
      "colour"
    );
    return validateReply[1];
  }

  // validates percentage inputs.
  function percentage(umbracoInput: string, defaultValue: string) {
    if (!umbracoInput || defaultValue == null) {
      defaultValue = "0%";
    }
    let regexArray = ["^[0-9]*(\\.[0-9]+)?$"];
    let validateReply = validateInput(
      umbracoInput,
      defaultValue,
      regexArray,
      "percentage"
    );
    if (validateReply[0]) {
      return validateReply[1] + "%";
    }
    regexArray = ["^[0-9]*(\\.[0-9]+)?\\%?$"];
    validateReply = validateInput(
      umbracoInput,
      defaultValue,
      regexArray,
      "percentage"
    );
    return validateReply[1];
  }

  // validate input of type array.
  function array(umbracoInput: any, defaultValue: any) {
    if (umbracoInput == null) {
      validateEmptyError("array value", defaultValue);
      return defaultValue;
    }
    if (!Array.isArray(umbracoInput)) {
      validateLogError(umbracoInput, "array", defaultValue);
      return defaultValue;
    }
    return umbracoInput;
  }

  // validates boolean values.
  function boolean(umbracoInput: boolean, defaultValue: boolean) {
    if (umbracoInput == null) {
      validateEmptyError("boolean value", defaultValue.toString());
      return defaultValue;
    }
    return umbracoInput;
  }

  // validate email values.
  function email(umbracoInput: string): boolean {
    if (!umbracoInput || umbracoInput === "--default") {
      return false;
    }
    const regex: RegExp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(umbracoInput)) {
      return true;
    } else {
      return false;
    }
  }

  // valifys regex and returns appropiate error to calling function.
  function validateInput(
    umbracoInput: string,
    defaultValue: any,
    regexArray: string[],
    inputType: string
  ) {
    if (umbracoInput == null) {
      validateEmptyError(inputType, defaultValue);
      return ["empty", defaultValue];
    }
    umbracoInput = removeAllWhiteSpaceLower(umbracoInput);
    if (validateRegex(regexArray, umbracoInput)) {
      return ["success", umbracoInput];
    }
    validateLogError(umbracoInput, inputType, defaultValue);
    return ["failure", defaultValue];
  }

  // valifys regex and returns appropiate error to calling function. No logs are displayed.
  function validateInputNoLog(
    umbracoInput: string,
    defaultValue: any,
    regexArray: string[]
  ) {
    if (umbracoInput == null) {
      return ["empty", defaultValue];
    }
    umbracoInput = removeAllWhiteSpaceLower(umbracoInput);
    if (validateRegex(regexArray, umbracoInput)) {
      return ["success", umbracoInput];
    }
    return ["failure", defaultValue];
  }

  // validates umbracoInput with the provided regex string.
  function validateRegex(regexArray: string[], umbracoInput: string) {
    for (const regItem of regexArray) {
      const reg = new RegExp(regItem);
      if (reg.test(umbracoInput)) {
        return true;
      }
    }
    return false;
  }

  function validateEmptyError(type: string, defaultValue: string) {
    /*console.log(
      "The Umbraco input value of type " +
        type +
        ". Is empty or null. The default value of " +
        defaultValue +
        " has been used instead."
    );*/
  }

  function validateLogError(value: string, type: string, defaultValue: string) {
    /*console.log(
      "The Umbraco input value " +
        value +
        " is not a valid " +
        type +
        ". The default value of " +
        defaultValue +
        " has been used instead."
    );*/
  }

  function removeAllWhiteSpace(strVal: string) {
    if (!strVal) {
      return "";
    }
    return strVal.replace(/\s+/g, "");
  }

  function removeAllWhiteSpaceLower(strVal: string) {
    if (!strVal) {
      return "";
    }
    return strVal.replace(/\s+/g, "").toLowerCase();
  }

  return {
    array,
    boolean,
    colour,
    fontStyle,
    fontTag,
    fontWeight,
    imageUrl,
    lineHeight,
    number,
    numberAllowNegative,
    percentage,
    size,
    string,
    textAlign,
    email,
    notNullOrUndefined
  };
}
export { validate };
