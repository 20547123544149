import { Image } from "@/ts/models/imageModel";
import { backgroundGalleryComp } from "@/ts/backgroundGalleryComp";
import { imageHelper } from "@/ts/imageHelperComp";
import { validate } from "@/ts/validationComp";
import { defineComponent } from "vue";
import { loadingScreen } from "@/ts/loadingScreenComp";

/*
Module initiates entire website.
Utilising DOM elements like this is not considred good vue architecture.
However I don't see any other way to do this effectively.
*/
export default defineComponent({
  data() {
    return {
      backgroundURL: "" as string,
      bodyElement: {} as HTMLElement,
      pageSettings: {} as Element,
    };
  },
  async mounted() {
    // mount loaded async component number
    let postNode: Element = document.getElementsByTagName("post-load")[0];
    let asyncModuleNum: number = Number(postNode.getAttribute('data-async-module-num')) as number;
    loadingScreen().addMax(asyncModuleNum);
    const width = window.innerWidth;
    this.initiateContainerSettings(width);
    this.pageSettings = document.getElementsByTagName("page-settings")[0];
    // retrieve body element.
    this.bodyElement = document.getElementsByClassName(
      "body"
    )[0] as HTMLElement;
    this.mountBackground(width);
  },
  // you are setting header position
  methods: {
    // loads in background for page if it exists.
    mountBackground(width: number) {
      let backgroundString: string = "";
      if (width > 991) {
        backgroundString = this.pageSettings.getAttribute("data-background")!;
      } else {
        backgroundString = this.pageSettings.getAttribute(
          "data-background-mobile"
        )!;
      }
      if (backgroundString.length > 0) {
        const backgroundObject: Image[] = (JSON.parse(backgroundString));
        const imageUrl = imageHelper().getResizedImage(backgroundObject, width);
        if (backgroundObject[0].Path.endsWith("mp4")) {
          const videoElement = this.bodyElement.getElementsByClassName(
            "body-video-background"
          )[0];
          (videoElement as HTMLElement).style.display = "flex";
          videoElement.innerHTML =
            "<video autoplay loop muted playsinline><source src='" +
            imageUrl +
            "' type='video/mp4'></video>";
        } else {
          const imageElement = this.bodyElement.getElementsByClassName(
            "body-background"
          )[0];
          imageElement.innerHTML =
          "<img src='" +
          imageUrl +
          "' alt='image-background'></img>";
        }
      }
    },

    // initiates the container settings
    initiateContainerSettings(width: number) {
      // eslint-disable-next-line no-undef
      const gridElements: HTMLCollectionOf<Element> =
        document.getElementsByClassName("grid-component-container");
      for (const element of gridElements) {
        this.setTheme(element);
        this.setDisplayed(element);
        this.setBackground(element, width);
      }
    },

    // initiate transparent background.
    initiateTransparent(element: Element) {
      const elementRow = element.closest(".grid-row-container") as HTMLElement;
      elementRow.style.background = "transparent";
    },

    // sets theme for section.
    setTheme(element: Element) {
      this.themeId = element.getAttribute("data-theme");
      if (this.themeId == null) {
        this.themeId = "Default";
      }
      element.closest(".grid-row-container").classList.add("t" + this.themeId);
    },

    // sets background of the section. Image or Transparent
    setBackground(element: Element, width: number) {
      const transparent: string = element.getAttribute("data-transparent");
      if (transparent !== null && transparent === "1") {
        this.initiateTransparent(element);
      } else {
        // retrieve background image
        let backgroundUrls: string[] = [];
        let backgroundString: string = "";
        // Check if image is in an array. If not create an array. This is used to transition from old dataset.
        // Code can be deleted if all sites has transitioned.
        if (width > 991) {
          backgroundString = element.getAttribute("data-backgroundimage");
        } else {
          backgroundString = element.getAttribute("data-backgroundimagemobile");
        }
        if (backgroundString && !backgroundString.startsWith("[")) {
          backgroundString = "[[\"Path\":\"" + backgroundString + "\"],\"Width\":-1]]";
        }
        if (backgroundString && backgroundString.length > 0) {
          const imageArray: Image[][] = JSON.parse(backgroundString);
          for (const image of imageArray) {
            backgroundUrls.push(imageHelper().getResizedImage(image, width))
          }
        }
        if (backgroundUrls.length > 0) {
          let animationDelay = validate().number(element.getAttribute("data-backgrounddelay"), 5);
          let animationDuration = validate().number(element.getAttribute("data-backgroundduration"), 1);
          // initiate background gallery element.
          const elementRow = element
            .closest(".grid-row-container")
            .getElementsByClassName(
              "grid-gallery-background"
            )[0] as HTMLElement;
          backgroundGalleryComp().addGallery(elementRow, backgroundUrls, animationDelay, animationDuration);
        }
      }
    },

    // initiates mobile/desktop display. Determines if section is displayed on mobile or desktop.
    setDisplayed(element: Element) {
      const desktopDisplayed: string = element
        .closest(".grid-component-container")
        .getAttribute("data-hide-desktop");
      if (desktopDisplayed !== null && desktopDisplayed === "1") {
        element.closest(".grid-row-container").classList.add("desktop-hidden");
      }
      const mobileDisplayed: string = element
        .closest(".grid-component-container")
        .getAttribute("data-hide-mobile");
      if (mobileDisplayed !== null && mobileDisplayed === "1") {
        element.closest(".grid-row-container").classList.add("mobile-hidden");
      }
    },

    // delay common method for use within the components.
    async delay(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
  }
});
