//Stores data for 1 infinite scroll item.
export class LatLngModel {
  public lat: number;
  public lng: number;

  constructor(lat: number, lng: number) {
    this.lat = lat;
    this.lng = lng;
  }

  public isSame(coordinate: LatLngModel) {
    return (coordinate.lat == this.lat && coordinate.lng == this.lng);
  }
}
