import { SearchResultModel } from "./searchResultModel";
import { getSuburbForSearch } from "./../../localeComp";

//stores data required by search function.
export class RecommendedSearchModel {
  private _saleSuburbRecommendations: string[];
  private _leaseSuburbRecommendations: string[];
  private _investSuburbRecommendations: string[];
  // data for all recommendations stored in asset file.
  public saleRecommendations: string[];
  public leaseRecommendations: string[];
  public investRecommendations: string[];
  public recommendationResults: RecommendationResultModel[];
  public recommendationSuburbResults: RecommendationResultModel[];
  // trigger used to detect on change events for components.
  public onChangeTrigger: number;
  // search settings.
  public isRelaxed: boolean;
  constructor() {
    this.saleRecommendations = [];
    this.leaseRecommendations = [];
    this.investRecommendations = [];
    this.recommendationResults = [];
    this.recommendationSuburbResults = [];
    this._saleSuburbRecommendations = [];
    this._leaseSuburbRecommendations = [];
    this._investSuburbRecommendations = [];
    this.onChangeTrigger = 0;
    this.isRelaxed = null;
  }

  public JSONcopy(jsobject: any): void {
    this.saleRecommendations = jsobject.saleRecommendations;
    this.leaseRecommendations = jsobject.leaseRecommendations;
    this.investRecommendations = jsobject.investRecommendations;
    for (let recommendation of jsobject.recommendationResults) {
      let newRecommendation: RecommendationResultModel =
        new RecommendationResultModel();
      newRecommendation.JSONcopy(recommendation);
      this.recommendationResults.push(newRecommendation);
    }
    for (let recommendation of jsobject.recommendationSuburbResults) {
      let newRecommendation: RecommendationResultModel =
        new RecommendationResultModel();
      newRecommendation.JSONcopy(recommendation);
      this.recommendationSuburbResults.push(newRecommendation);
    }
    this.onChangeTrigger = this.onChangeTrigger + 1;
    this.isRelaxed = jsobject.isRelaxed;
  }

  public sort(): void {
    this._saleSuburbRecommendations.sort();
    this._leaseSuburbRecommendations.sort();
    this._investSuburbRecommendations.sort();
    this.saleRecommendations.sort();
    this.leaseRecommendations.sort();
    this.investRecommendations.sort();
    this.saleRecommendations = this._saleSuburbRecommendations.concat(
      this.saleRecommendations
    );
    this.leaseRecommendations = this._leaseSuburbRecommendations.concat(
      this.leaseRecommendations
    );
    this.investRecommendations = this._investSuburbRecommendations.concat(
      this.investRecommendations
    );
    this.onChangeTrigger = this.onChangeTrigger + 1;
  }

  public addState(state: string, listingType: string) {
    this.addStateRecommendation(state);
    if (listingType.includes("L")) {
      this.addToArray(this.leaseRecommendations, state);
    }
    if (listingType.includes("V")) {
      this.addToArray(this.saleRecommendations, state);
    }
    if (listingType.includes("I")) {
      this.addToArray(this.investRecommendations, state);
    }
  }

  public addCity(city: string, state: string, listingType: string) {
    this.addCityRecommendation(city, state);
    if (listingType.includes("L")) {
      this.addToArray(this.leaseRecommendations, this.createCityName(city, state));
    }
    if (listingType.includes("V")) {
      this.addToArray(this.saleRecommendations, this.createCityName(city, state));
    }
    if (listingType.includes("I")) {
      this.addToArray(this.investRecommendations, this.createCityName(city, state));
    }
  }

  public addAddress(searchResult: SearchResultModel, listingType: string) {
    let searchResultTmp: SearchResultModel = new SearchResultModel();
    searchResultTmp.clone(searchResult);
    searchResultTmp.siteName = "";
    const addressFull: string = searchResult.toString();
    this.addAddressRecommendation(searchResult);
    const addressPartial: string = searchResultTmp.toString();
    this.addAddressRecommendation(searchResultTmp);
    if (listingType.includes("L")) {
      this.addToArray(this.leaseRecommendations, addressFull);
      this.addToArray(this.leaseRecommendations, addressPartial);
    }
    if (listingType.includes("V")) {
      this.addToArray(this.saleRecommendations, addressFull);
      this.addToArray(this.saleRecommendations, addressPartial);
    }
    if (listingType.includes("I")) {
      this.addToArray(this.investRecommendations, addressFull);
      this.addToArray(this.investRecommendations, addressPartial);
    }
  }

  public addSuburb(
    suburb: string,
    postcode: string,
    state: string,
    listingType: string,
  ): void {
    if (!state) {
      state = "";
    }
    if (!postcode) {
      postcode = "";
    }
    let postcodeRegional = getSuburbForSearch(postcode);
    if (listingType.includes("L")) {
      this.addToArray(
        this._leaseSuburbRecommendations,
        this.createSuburbName(suburb, state),
        suburb
      );
      this.addToArray(this.leaseRecommendations, postcodeRegional);
    }
    if (listingType.includes("V")) {
      this.addToArray(
        this._saleSuburbRecommendations,
        this.createSuburbName(suburb, state),
        suburb
      );
      this.addToArray(this.saleRecommendations, postcodeRegional);
    }
    if (listingType.includes("I")) {
      this.addToArray(
        this._investSuburbRecommendations,
        this.createSuburbName(suburb, state),
        suburb
      );
      this.addToArray(this.investRecommendations, postcodeRegional);
    }
    // add suburb to recommendations list.
    this.addSuburbRecommendation(suburb, postcode, state);
    if (postcode !== "") {
      // add postcode to recommendations list
      this.addPostcodeRecommendation(suburb, postcodeRegional, state);
      if (postcode !== postcodeRegional) {
        let postcodeNoSpace = postcode.replace(" ", "");
        this.addPostcodeRecommendation(
          suburb,
          postcode,
          state,
          postcodeNoSpace
        );
        this.addPostcodeRecommendation(suburb, postcode, state);
      }
    }
  }

  public setupSearchSettings(isRelaxed: boolean) {
    this.isRelaxed = isRelaxed;
  }

  private createSuburbName(suburb: string, state: string): string {
    return suburb + ", " + state;
  }

  private createCityName(city: string, state: string): string {
    return city + ", " + state;
  }

  private addStateRecommendation(state: string) {
    let findId = this.findRecommendationById(state);
    if (findId === "-1") {
      let recommendation = new RecommendationResultModel();
      recommendation.searchResult.state = state;
      recommendation.searchIdentifier = this.fullSanitize(state);
      if (recommendation.searchIdentifier.length > 0) {
        this.recommendationResults.push(recommendation);
      }
    }
  }

  private addCityRecommendation(city: string, state: string) {
    let cityName = this.createCityName(city, state);
    let findId = this.findRecommendationById(cityName);
    if (findId === "-1") {
      let recommendation = new RecommendationResultModel();
      recommendation.searchResult.city = city;
      recommendation.searchResult.state = state;
      recommendation.searchIdentifier = this.fullSanitize(cityName);
      if (recommendation.searchIdentifier.length > 0) {
        this.recommendationResults.push(recommendation);
      }
    }
  }

  private addPostcodeRecommendation(
    suburb: string,
    postcode: string,
    state: string,
    identifier?: string
  ) {
    let findId = this.findRecommendationById(postcode);
    if (findId === "-1") {
      let recommendation = new RecommendationResultModel();
      recommendation.searchResult.suburb = suburb;
      recommendation.searchResult.postcode = this.sanitize(postcode);
      recommendation.searchResult.state = state;
      if (identifier) {
        recommendation.searchIdentifier = this.fullSanitize(identifier);
      } else {
        recommendation.searchIdentifier = this.fullSanitize(postcode);
      }
      if (recommendation.searchIdentifier.length > 0) {
        this.recommendationResults.push(recommendation);
      }
    } else {
      // clear suburb/state if it deos not match with previous value with the same.
      // this is done because the user may not have entered the data correctly.
      if (
        this.recommendationResults[findId].searchResult.suburb !==
        this.sanitize(suburb)
      ) {
        this.recommendationResults[findId].searchResult.suburb = "";
      }
      if (
        this.recommendationResults[findId].searchResult.state !==
        this.sanitize(state)
      ) {
        this.recommendationResults[findId].searchResult.state = "";
      }
    }
  }

  private addSuburbRecommendation(
    suburb: string,
    postcode: string,
    state: string
  ) {
    let findId = this.findRecommendationById(suburb);
    if (findId === "-1") {
      let recommendation = new RecommendationResultModel();
      recommendation.searchResult.searchText = this.sanitize(suburb);
      recommendation.searchIdentifier = this.fullSanitize(suburb);
      if (recommendation.searchIdentifier.length > 0) {
        this.recommendationResults.push(recommendation);
      }
    }
    // add suburb as Recommendation
    findId = this.findSuburbRecommendationById(
      this.createSuburbName(suburb, state)
    );
    if (findId === "-1") {
      let recommendation = new RecommendationResultModel();
      recommendation.searchResult.suburb = this.sanitize(suburb);
      // in not relaxed mode.
      if (!this.isRelaxed) {
        recommendation.searchResult.state = this.sanitize(state);
      }
      //recommendation.searchResult.state = state;
      recommendation.searchIdentifier = this.sanitize(
        this.createSuburbName(suburb, state)
      );
      if (recommendation.searchIdentifier.length > 0) {
        this.recommendationSuburbResults.push(recommendation);
      }
    }
  }

  private addAddressRecommendation(searchResult: SearchResultModel) {
    let searchResultString: string = searchResult.toString();
    let findId = this.findRecommendationById(searchResultString);
    if (findId === "-1") {
      let recommendation = new RecommendationResultModel();
      recommendation.searchResult = searchResult;
      recommendation.searchIdentifier = this.fullSanitize(searchResultString);
      if (recommendation.searchIdentifier.length > 0) {
        this.recommendationResults.push(recommendation);
      }
    }
  }

  public async findRecommendationResultById(
    identifier: string
  ): Promise<SearchResultModel | null> {
    // determine if isRelaxed value is set before initiating search.
    while (this.isRelaxed == null) {
      await this.delay(100);
    }
    let recId: string = this.findRecommendationById(identifier);
    if (recId !== "-1") {
      return this.recommendationResults[recId].searchResult;
    }
    recId = this.findSuburbRecommendationById(identifier);
    if (recId !== "-1") {
      return this.recommendationSuburbResults[recId].searchResult;
    }
    return null;
  }

  private findRecommendationById(identifier: string): string {
    identifier = this.fullSanitize(identifier);
    for (let recommendationId in this.recommendationResults) {
      if (
        this.recommendationResults[recommendationId].searchIdentifier ===
        identifier
      ) {
        return recommendationId;
      }
    }
    return "-1";
  }

  private findSuburbRecommendationById(identifier: string): string {
    identifier = this.sanitize(identifier);
    for (let recommendationId in this.recommendationSuburbResults) {
      if (
        this.recommendationSuburbResults[recommendationId].searchIdentifier ===
        identifier
      ) {
        return recommendationId;
      }
    }
    return "-1";
  }

  private sanitize(inputString: string) {
    if (inputString) {
      inputString = inputString.toLowerCase().trim();
      return inputString;
    }
    return "";
  }
  private fullSanitize(inputString: string) {
    if (inputString) {
      inputString = inputString.toLowerCase().trim();
      inputString = inputString.replace(/[^a-zA-Z0-9\\s]/g, " ");
      inputString = inputString.replace(/  +/g, " ");
      return inputString;
    }
    return "";
  }

  // Adds item onto array.
  private addToArray(list: string[], value: string, suburb?: string): boolean {
    // if a suburb is given, check if last item has same subrub. If so, do not add.
    if (suburb && this.isRelaxed) {
      try {
        const item = list[list.length - 1];
        const suburbString: string = item.split(",")[0].trim();
        if (suburb.trim() === suburbString) {
          return false;
        }
      } catch (ex) {}
    }
    if (list.indexOf(value) === -1 && value !== "") {
      list.push(value);
      return true;
    }
    return false;
  }

  private async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}

class RecommendationResultModel {
  // identifier for search result.
  public searchIdentifier: string;
  // stored final search data for search recommendation
  public searchResult: SearchResultModel;
  constructor() {
    this.searchIdentifier = "";
    this.searchResult = new SearchResultModel();
  }
  public JSONcopy(jsobject: any): void {
    this.searchIdentifier = jsobject.searchIdentifier;
    this.searchResult.JSONcopy(jsobject.searchResult);
  }
}
