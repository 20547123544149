import { SearchFilters } from "./searchFiltersModel";
export class SearchOptions {
  public isAscending: boolean;
  public sortProperty: string;
  public activeListingType: string;
  public searchText: string;
  public recommendedList: string[];
  public filters: SearchFilters;
  public totalCount: number;
  [key: string]: any;

  constructor() {
    this.initiate();
  }

  public initiate() {
    this.isAscending = true;
    this.sortProperty = "suburb";
    this.activeListingType = "L";
    this.searchText = "";
    this.recommendedList = [];
    this.filters = new SearchFilters();
    this.totalCount = -1;

  }

  public cast(castModel: Record<string, any>) {
    if (castModel["isAscending"]) {
      this.isAscending = castModel["isAscending"];
    }
    if (castModel["sortProperty"]) {
      this.sortProperty = castModel["sortProperty"];
    }
    if (castModel["activeListingType"]) {
      this.activeListingType = castModel["activeListingType"];
    }
    if (castModel["searchText"]) {
      this.searchText = castModel["searchText"];
    }
    this.filters.cast(castModel.filters);
  }

  public castParameters(castModel: Record<string, any>): void {
    let objectKeys: string[] = Object.keys(this);
    let isUseParameters: boolean = false;
    // remove keys which are unused as parameters.
    objectKeys = objectKeys.filter((item) => item !== "recommendedList");
    objectKeys = objectKeys.filter((item) => item !== "filters");
    objectKeys = objectKeys.filter((item) => item !== "totalCount");
    for (var key of objectKeys) {
      if (castModel.hasOwnProperty(key)) {
        isUseParameters = true;
      }
    }
    if (!isUseParameters) {
      isUseParameters = this.filters.castParameters(castModel);
    }
    // if any parameters match the object's name, reinitiate object values with parameters.
    if (isUseParameters) {
      this.initiate();
      if (castModel["isAscending"]) {
        if (castModel["isAscending"] === "false") {
          this.isAscending = false;
        }
      }
      if (castModel["sortProperty"]) {
        this.sortProperty = castModel["sortProperty"];
      }
      if (castModel["activeListingType"]) {
        this.activeListingType = castModel["activeListingType"];
      }
      if (castModel["searchText"]) {
        this.searchText = castModel["searchText"];
      }
      this.filters.cast(castModel);
    }
  }

  public getParameterChanges(): (
    | Record<string, string | boolean | number>
    | Array<string>
  )[] {
    let result: (Record<string, string | boolean | number> | Array<string>)[] =
      [{}, new Array()];
    let changeList: Record<string, string | boolean | number> | Array<string> =
      {};
    let deleteList: Array<string> = [];
    const defaultSO: SearchOptions = new SearchOptions();
    if (this.isAscending !== defaultSO.isAscending) {
      changeList["isAscending"] = this.isAscending;
    } else {
      deleteList.push("isAscending");
    }
    if (this.sortProperty !== defaultSO.sortProperty) {
      changeList["sortProperty"] = this.sortProperty;
    } else {
      deleteList.push("sortProperty");
    }
    if (this.activeListingType !== defaultSO.activeListingType) {
      changeList["activeListingType"] = this.activeListingType;
    } else {
      deleteList.push("activeListingType");
    }
    if (this.searchText !== defaultSO.searchText) {
      changeList["searchText"] = this.searchText;
    } else {
      deleteList.push("searchText");
    }
    result[0] = changeList;
    result[1] = deleteList;
    result = this.filters.getParameterChanges(result);
    return result;
  }
}
